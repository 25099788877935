import BottomSheetComponent from "components/bottomSheet/BottomSheetComponent";
import ButtonItem from "components/formItems/button/ButtonItem";
import InputItem from "components/formItems/input/InputItem";
import OpenNotificationWithIcon from "components/notificationWithIcon/OpenNotificationWithIcon ";
import { calcFinalFTEE } from "functions/functions";
import TitleWithUnderline from "layouts/titleWithUnderline/TitleWithUnderline";
import Axios from "middleware/axiosInstance";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const AddChildComponent = ({
  showBottomSheet,
  setShowBottomSheet,
  BottomSheetTypeIsEdit,
  child,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState(child?.name || null);
  const [age, setAge] = useState(child?.age || null);
  const [gender, setGender] = useState(child?.gender || "FE");
  const [weight, setWeight] = useState(child?.weight || null);
  const [height, setHeight] = useState(child?.height || null);
  const [BMI, setBMI] = useState(null);
  const [childrenList, setChildrenList] = useState([]);

  const nameChangeHandler = (e) => {
    setName(e.target.value);
  };
  const ageChangeHandler = (e) => {
    setAge(e.target.value);
  };
  const heightChangeHandler = (e) => {
    setHeight(e.target.value);
  };
  const WeightChangeHandler = (e) => {
    setWeight(e.target.value);
  };

  function generateRandom4DigitNumber() {
    // Generate a random number between 1000 and 9999
    var randomNumber = Math.floor(Math.random() * 9000) + 1000;

    return randomNumber;
  }

  const calcBMI = () => {
    if ((age > 0, weight > 0, height > 0, name)) {
      if (calcFinalFTEE(gender, age, weight, height) > 0) {
        setBMI(calcFinalFTEE(gender, age, weight, height));
        const temp = [...childrenList];
        temp.push({
          name: name,
          FTEE: calcFinalFTEE(gender, age, weight, height),
          id: generateRandom4DigitNumber(),
        });
        localStorage.setItem("test-child-list", JSON.stringify(temp));
        setChildrenList(temp);
        console.log(temp);
        setShowBottomSheet(false);
      }
    } else alert("please complete the form!");
  };

  const addChildHandler = () => {
    Axios.post("/account/children/", {
      age,
      gender,
      name,
      parent: 2,
      height,
      weight,
    })
      .then((res) => {
        if (res.status === 201) {
          OpenNotificationWithIcon(
            t("addSuccessful"),
            t("newChildAddedSuccessfully")
          );
          setShowBottomSheet(false);
        }
      })
      .catch((err) => {});
  };
  const editChildHandler = () => {
    Axios.put(`/account/children/${child.id}/`, {
      age,
      gender,
      // id,
      name,
      parent: 2,
      height,
      weight,
    })
      .then((res) => {
        if (res.status === 200) {
          OpenNotificationWithIcon(
            t("editSuccessful"),
            t("ChildEditedSuccessfully")
          );
          setShowBottomSheet(false);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setBMI(null);
  }, [showBottomSheet]);
  return (
    <BottomSheetComponent
      showBottomSheet={showBottomSheet}
      setShowBottomSheet={setShowBottomSheet}
    >
      <div className="h-auto my-3  flex flex-col justify-start items-center ">
        <TitleWithUnderline
          title={t(`${BottomSheetTypeIsEdit ? `edit child` : `add new child`}`)}
          className="all-center mt-3 mb-2"
        />
        <form className="flex items-start flex-wrap ">
          <InputItem
            inputType="text"
            inputName="childName"
            label={t("child name")}
            placeHolder={t("John Aria")}
            changeHandler={(e) => nameChangeHandler(e)}
            value={name}
          />
          <InputItem
            inputType="number"
            inputName="childAge"
            label={t("child age")}
            placeHolder="15"
            changeHandler={(e) => ageChangeHandler(e)}
            value={age}
          />
          <InputItem
            inputType="number"
            inputName="childHeight"
            label={t("child height")}
            placeHolder="138"
            changeHandler={(e) => heightChangeHandler(e)}
            value={height}
          />
          <InputItem
            inputType="number"
            inputName="childWeight"
            label={t("child weight")}
            placeHolder="60"
            changeHandler={(e) => WeightChangeHandler(e)}
            value={weight}
          />
          <select
            className="w-full mt-[10px] bg-[#EDF7E3] h-[36px] border rounded-md"
            name="gender"
            id="gender"
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="FE" selected={gender === "FE"}>
              {t("girl")}
            </option>
            <option value="MA" selected={gender === "MA"}>
              {t("boy")}
            </option>
          </select>
        </form>

        {BMI ? `BMI: ${BMI}` : null}
        <ButtonItem
          className="bg-orange-default mb-4"
          name={BottomSheetTypeIsEdit ? t("save changes") : t("add new child")}
          onClick={BottomSheetTypeIsEdit ? editChildHandler : addChildHandler}
        />
      </div>
    </BottomSheetComponent>
  );
};
export default AddChildComponent;
