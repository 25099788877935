import { useEffect, useState } from "react";
// import almonds from "assets/images/temporaryImages/nuts/tinyjpg/almonds.jpg";

import BottomSheetComponent from "components/bottomSheet/BottomSheetComponent";
import ShowNutsDetails from "components/showNutDetails/ShowNutsDetails";
import classes from "./ProductList.module.scss";

import almonds from "assets/images/temporaryImages/nuts-pack/almonds.png";
import apple from "assets/images/temporaryImages/nuts-pack/apple.png";
import cheese from "assets/images/temporaryImages/nuts-pack/cheese.png";
import orangeJuice from "assets/images/temporaryImages/nuts-pack/orangeJuice.png";
import strawberryIcecream from "assets/images/temporaryImages/nuts-pack/strawberryIcecream.png";
import walnut from "assets/images/temporaryImages/nuts-pack/walnut.png";
import Axios from "middleware/axiosInstance";
import { useTranslation } from "react-i18next";
import Loading from "layouts/Loading/Loading";
// interface productListProps {
//   // price: number;
//   // nutsImage: any;
//   // nutsName: string;
//   setTemporaryCard?: any;
//   temporaryCard?: any;
//   // id?: number;
// }

const ProductList = ({ orderType, selectedChildFTEE, datetime }) => {
  // const products = [
  //   {
  //     category: "nuts",
  //     categoryId: 0,
  //     image: almonds,
  //     product: [
  //       {
  //         id: 200,
  //         name: "almonds",
  //         image: almonds,
  //         category: "nuts",
  //         categoryId: 0,
  //         price: 3,
  //         cal: 240,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         id: 201,
  //         name: "almonds",
  //         image: almonds,
  //         category: "nuts",
  //         categoryId: 0,
  //         price: 3,
  //         cal: 240,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     category: "fruits",
  //     categoryId: 1,
  //     image: apple,
  //     product: [
  //       {
  //         id: 202,
  //         name: "apple",
  //         image: apple,
  //         category: "fruits",
  //         categoryId: 1,
  //         cal: 310,
  //         price: 3,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         id: 203,
  //         name: "apple",
  //         image: apple,
  //         category: "fruits",
  //         categoryId: 1,
  //         cal: 310,
  //         price: 3,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     category: "vege",
  //     categoryId: 2,
  //     image: walnut,
  //     product: [
  //       {
  //         id: 204,
  //         name: "carrots",
  //         image: walnut,
  //         category: "vege",
  //         categoryId: 2,
  //         cal: 470,
  //         price: 3,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         id: 205,
  //         name: "carrots",
  //         image: walnut,
  //         category: "vege",
  //         categoryId: 2,
  //         cal: 470,
  //         price: 3,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     category: "bread",
  //     categoryId: 3,
  //     image: strawberryIcecream,
  //     product: [
  //       {
  //         id: 206,
  //         name: "toast",
  //         image: strawberryIcecream,
  //         category: "bread",
  //         categoryId: 3,
  //         cal: 190,
  //         price: 3,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         id: 207,
  //         name: "toast",
  //         image: strawberryIcecream,
  //         category: "bread",
  //         categoryId: 3,
  //         cal: 190,
  //         price: 3,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     category: "Dairy",
  //     categoryId: 4,
  //     image: cheese,
  //     product: [
  //       {
  //         id: 208,
  //         name: "cheese",
  //         image: cheese,
  //         category: "Dairy",
  //         categoryId: 4,
  //         cal: 250,
  //         price: 2,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         id: 209,
  //         name: "cheese",
  //         image: cheese,
  //         category: "Dairy",
  //         categoryId: 4,
  //         cal: 250,
  //         price: 2,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     category: "juice",
  //     categoryId: 5,
  //     image: orangeJuice,
  //     product: [
  //       {
  //         id: 210,
  //         name: "orange juice",
  //         image: orangeJuice,
  //         category: "juice",
  //         categoryId: 5,
  //         cal: 430,
  //         price: 3,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //       {
  //         id: 211,
  //         name: "orange juice",
  //         image: orangeJuice,
  //         category: "juice",
  //         categoryId: 5,
  //         cal: 430,
  //         price: 3,
  //         sizes: [
  //           {
  //             id: 301,
  //             name: "100g",
  //             price: 10,
  //             cal: 70,
  //           },
  //           {
  //             id: 302,
  //             name: "200g",
  //             price: 15,
  //             cal: 140,
  //           },
  //           {
  //             id: 303,
  //             name: "300g",
  //             price: 20,
  //             cal: 210,
  //           },
  //         ],
  //         options: [
  //           {
  //             id: 1,
  //             name: "type1",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "salty",
  //                 value: "salty",
  //               },
  //               {
  //                 id: 2,
  //                 name: "pepper",
  //                 value: "pepper",
  //               },
  //             ],
  //           },
  //           {
  //             id: 1,
  //             name: "type2",
  //             options: [
  //               {
  //                 id: 1,
  //                 name: "with skin",
  //                 value: "with skin",
  //               },
  //               {
  //                 id: 2,
  //                 name: "without skin",
  //                 value: "without skin",
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  const hostAddress = process.env.REACT_APP_Host_Address;
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  const [products, setProducts] = useState(null);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [sizeOfTopOfPolygonArrow, setSizeOfTopOfPolygonArrow] = useState(22);

  useEffect(() => {
    if (showProductDetails === false) {
      setSelectedProduct(null);
    }
  }, [showProductDetails]);

  useEffect(() => {
    setSizeOfTopOfPolygonArrow(
      selectedCategory * 93 + 14 * selectedCategory + 22
    );
  }, [selectedCategory]);

  useEffect(() => {
    if (orderType === "school" && selectedChildFTEE !== undefined) {
      getProductsAsChildFTTE();
    } else if (orderType === "event") {
      getDefalutProducts();
    } else alert("error in orderType");
  }, []);

  const getDefalutProducts = () => {
    Axios.get(`/product/food-category/?datetime=${datetime}`)
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => console.error(err));
  };

  const getProductsAsChildFTTE = () => {
    Axios.get("/product/food-category/")
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className={classes.container}>
      {products ? (
        <>
          <div className={classes.categoriesContainer}>
            {products?.map((product, index) => {
              return (
                <div
                  className={`${classes.category} ${
                    index === selectedCategory
                      ? `${classes.activeCategory}`
                      : ""
                  }`}
                  onClick={() => {
                    setSelectedCategory(index);
                  }}
                >
                  <div className={classes.categoryImageContainer}>
                    <img
                      src={product.image}
                      alt="#"
                      className={classes.categoryImage}
                    />
                  </div>
                  <span className={classes.categoryName}>
                    {product[`name_${lang}`]}
                  </span>
                </div>
              );
            })}
          </div>
          {/* <div className={classes.block}></div> */}

          <div className={classes.productsContainer}>
            <div
              className={`${classes.PolygonArrow}`}
              style={{ top: `${sizeOfTopOfPolygonArrow}px` }}
            ></div>

            {products &&
              products[selectedCategory]?.products?.map((product) => {
                return (
                  <div
                    className={classes.product}
                    onClick={() => {
                      setSelectedProduct(product);
                      setShowProductDetails(true);
                    }}
                  >
                    <div className={classes.productImageContainer}>
                      <img
                        src={`${hostAddress}${product.image}`}
                        alt="#"
                        className={classes.productImage}
                      />
                    </div>
                    <span className={classes.productName}>
                      {product[`name_${lang}`]}
                    </span>
                  </div>
                );
              })}
          </div>
          <BottomSheetComponent
            showBottomSheet={showProductDetails}
            setShowBottomSheet={setShowProductDetails}
          >
            {selectedProduct && (
              <ShowNutsDetails
                selectedProduct={selectedProduct}
                orderType={orderType}
              />
            )}
          </BottomSheetComponent>
        </>
      ) : (
        <div className="flex justify-center w-full">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default ProductList;
