import notFoundIcon from "assets/icons/errors/notFound.svg";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="w-100 h-screen all-center flex-col bg-[#FEA386] text-center">
      <h1>{t("oops")}</h1>
      <img src={notFoundIcon} className="w-28" alt="error-icon" />
      <h2>{t("error404")}</h2>
      <h3>{t("NotFoundError")}</h3>
      <h3>{t("descriptionForError404")}</h3>
      <div className="bg-white px-5 py-3 rounded-[10px]">
        {t("go back to home")}
      </div>
    </div>
  );
};
export default NotFound;