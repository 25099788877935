import cartIcon from "assets/icons/footer/shop.png";
import BottomSheetComponent from "components/bottomSheet/BottomSheetComponent";
import CustomTinyOrderDetails from "components/customTinyOrderDetails/CustomTinyOrderDetails";
import PackCardForSchoolAndEventInCart from "components/packCardForCart/PackCardForSchoolAndEventInCart";
import SelectAddress from "components/selectAddress/SelectAddress";
import { useCartContext } from "context/CartContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import classes from "./Cart.module.scss";
import PackSchoolCustomInCart from "components/packSchoolCustomInCart/PackSchoolCustomInCart";
import PackEventCustomInCart from "components/eventCustomInCart/PackEventCustomInCart";

const Cart = () => {
  const { t } = useTranslation();

  const { cart, setCart } = useCartContext();

  const addresses = [
    {
      id: 0,
      name: "john",
      address: "No6,Rose Avenue,Kansas.",
    },
    {
      id: 1,
      name: "Eldon",
      address: "No2,Kansas Ave,Muscut.",
    },
    {
      id: 2,
      name: "Cormac",
      address: "No7,Madison Ave,North carolina.",
    },
    {
      id: 3,
      name: "Cedric",
      address: "No1,Kechi Ave,Connecticut",
    },
  ];

  const [detailsOfTiny, setDetailsOfTiny] = useState(null);
  const [showCustomDetails, setShowCustomDetails] = useState(false);
  const showCustomDetailsHandler = () => {
    setShowCustomDetails(true);
  };
  const [showNextStepBottomSheet, setShowNextStepBottomSheet] = useState(false);
  const setShowBottomSheetHandler = () => {
    setShowNextStepBottomSheet(true);
  };

  return (
    <>
      <div className="pb-[160px] h-[calc(90%-100px)] overflow-y-scroll">
        <div className="flex flex-row justify-start">
          <img src={cartIcon} alt="cart-icon" />
          <span className="font-bold text-xl mx-3">{t("cart")}</span>
        </div>
        {/* <div className="mb-3">
          <SelectAddress
            addresses={addresses}
            setSelectedAddress={setSelectedAddress}
            selectedAddress={selectedAddress}
          />
        </div> */}
        {/* <TitleWithUnderline title={t("cart")} className="all-center" />*/}
        <div className="flex flex-col content-center items-center">
          {cart.length > 0 ? (
            cart.map((cart, cartIndex) => {
              return (
                <div className="w-full">
                  {/* <div className={classes.orderDate}>
                    <span className="border-b-2 text-center w-fit border-blue-default">
                      {cart.date}
                    </span>
                  </div> */}
                  <div className="w-full flex flex-col gap-3">
                    {cart?.school ? (
                      <>
                        {/* <div className={classes.orderDate}>
                          <span className="border-b-2 text-center w-fit border-green-default">
                            {t("school")}
                          </span>
                        </div> */}
                        {cart?.school?.map((order, schoolIndex) => {
                          return (
                            <>
                              {order.tinyType === "custom" ? (
                                <>
                                  <div className="mt-4">
                                    <SelectAddress addresses={addresses} />
                                  </div>
                                  <PackSchoolCustomInCart
                                    // pack={order.tiny.slice(1)}
                                    cartIndex={cartIndex}
                                    schoolIndex={schoolIndex}
                                  />
                                </>
                              ) : (
                                // <PackCardForSchoolAndEventInCart
                                //   price={order?.tiny[1]?.price}
                                //   nutsImage={almonds}
                                //   nutsName={order?.tiny[1]?.nutsName}
                                // />
                                <>
                                  <div className="mt-4">
                                    <SelectAddress addresses={addresses} />
                                  </div>

                                  <PackCardForSchoolAndEventInCart
                                    cartIndex={cartIndex}
                                    schoolIndex={schoolIndex}
                                    // eventIndex={eventIndex}
                                  />
                                </>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : null}
                    {cart?.event?.map((eventOrder, eventIndex) => {
                      console.log("eventOrder", eventOrder);
                      return (
                        <>
                          {eventOrder.tinyType === "custom" ? (
                            <>
                              <div className="mt-4">
                                <SelectAddress addresses={addresses} />
                              </div>
                              <PackEventCustomInCart
                                cartIndex={cartIndex}
                                eventIndex={eventIndex}
                                // schoolIndex={schoolIndex}
                              />
                            </>
                          ) : (
                            <>
                              <div className="mt-4">
                                <SelectAddress addresses={addresses} />
                              </div>
                              {eventOrder.tiny.map((item, packIndex) => {
                                console.log("injaa");
                                return (
                                  <PackCardForSchoolAndEventInCart
                                    cartIndex={cartIndex}
                                    eventIndex={eventIndex}
                                    packIndex={packIndex}
                                    // schoolIndex={schoolIndex}
                                  />
                                );
                              })}
                            </>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="w-full h-[50vh] text-center ">
              <span className="mt-[20vh] block font-bold">
                {t("cart is empty")}
              </span>
            </div>
          )}
          {/* {cart.length === 0 ? null : (
            <>
              <BeforePaymentSummary />
              <button
                onClick={() => setShowBottomSheetHandler()}
                className="fr-primary-button block"
              >
                {t("Proceed to Checkout")}
              </button>
            </>
          )} */}
        </div>
        <BottomSheetComponent
          showBottomSheet={showCustomDetails}
          setShowBottomSheet={setShowCustomDetails}
        >
          <div className="w-full flex justify-center">
            <div className="max-w-[500px]">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className={`mySwiper width-auto-swiper-slide`}
                spaceBetween={12}
                slidesPerView={"auto"}
                loop={true}
              >
                {detailsOfTiny?.map((item) => {
                  return (
                    <SwiperSlide>
                      <CustomTinyOrderDetails order={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </BottomSheetComponent>
        <BottomSheetComponent
          showBottomSheet={showNextStepBottomSheet}
          setShowBottomSheet={setShowNextStepBottomSheet}
        >
          <div className="all-center flex-col">
            <Link
              to="/order/event"
              className="fr-orange-button w-[260px] my-3 text-center"
            >
              {t("add onother event")}
            </Link>
            <Link
              to="/order/school"
              className="fr-orange-button  w-[260px] my-3 text-center"
            >
              {t("add onother school")}
            </Link>
            <Link
              to="/addresses"
              className="fr-primary-button  w-[260px] my-3 text-center"
            >
              {t("Proceed to Checkout")}
            </Link>
          </div>
        </BottomSheetComponent>
      </div>

      {cart.length > 0 ? (
        <div className={classes.totalAndCoupon}>
          <div className={classes.couponContainer}>
            <input
              type="text"
              className="w-3/5 h-full px-2"
              placeholder="first-order"
            />
            <button className="w-2/5 h-full rounded-[10px] bg-[#FEA386] color-[#fff]">
              {t("addCoupon")}
            </button>
          </div>
          <div className="w-full h-[3px] bg-[#FBBB23] my-4 rounded-md" />
          <div className="flex flex-row gap-4">
            <div className="text-[#000] flex flex-col  dir-by-lang">
              <span className="font-light text-start">{t("Total amount")}</span>
              <span className="font-bold text-lg text-center">- - $</span>
            </div>
            <Link to="/order-details" className="grow bg-[#87CB44] rounded-[10px] color-[#fff] outline-none all-center">
              {t("completeTheOrder")}
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default Cart;
