import { ReactComponent as Minus } from "assets/icons/minus-gray.svg";
import { ReactComponent as Plus } from "assets/icons/plus-green.svg";
import BottomSheetComponent from "components/bottomSheet/BottomSheetComponent";
import ShowproductDetails from "components/showProductDetails/ShowproductDetails";
import { useTemporaryCartContext } from "context/TemporaryCartContext";
import { decreaseCountOfPack, increaseCountOfPack } from "functions/functions";
import { useLayoutEffect, useState } from "react";
import classes from "./PackCard.module.scss";
import TrayForPack from "assets/images/backgrounds/tray-for-pack.png";
import TrayContentForPack from "assets/images/temporaryImages/tinyContent/six-item.png";
import { useTranslation } from "react-i18next";
// interface packCardProps {
//   price: number;
//   nutsImage: any;
//   nutsName: string;
//   settemporaryCart?: any;
//   temporaryCart?: any;
//   id?: number;
// }
const PackCard = ({
  pack,
  // id,
  // price,
  // nutsImage,
  // nutsName,
  orderTypeIsSchool,
  // cal,
}) => {
  const hostAddress = process.env.REACT_APP_Host_Address;

  console.log(pack);

  const { i18n, t } = useTranslation();
  const lang = i18n.language;
  const [count, setCount] = useState(0);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const { temporaryCart, setTemporaryCart } = useTemporaryCartContext();

  useLayoutEffect(() => {
    // if (orderTypeIsSchool) checkCardAndSetCountForSchool();
    // checkCardAndSetCountForSchool();
    // else if (!orderTypeIsSchool) {
    //   checkCardAndSetCountForEvent();
    // }
  }, [temporaryCart]);
  // if this card added to cart so we set count of card
  // const checkCardAndSetCountForSchool = () => {
  //   console.log("checkCardAndSetCountForSchool");
  //   if (id && temporaryCart && temporaryCart.length > 0) {
  //     const OrderTypeNotSchool =
  //       temporaryCart.findIndex((item) => item.orderType === "school") === -1;
  //     const OrderTypeNotPack =
  //       temporaryCart.findIndex((item) => item.tinyType === "pack") === -1;
  //     if (OrderTypeNotSchool | OrderTypeNotPack) {
  //       setCount(0);
  //     }
  //     const existingItemIndex = temporaryCart.findIndex(
  //       (item) => item.id === id
  //     );
  //     if (existingItemIndex !== -1) {
  //       setCount(temporaryCart[existingItemIndex].count);
  //     } else setCount(0);
  //   } else if (temporaryCart[0]?.count === 0 || temporaryCart.length === 0)
  //     setCount(0);
  // };

  return (
    <div className="w-full all-center">
      <div className={classes.PackCardContainer}>
        <div className="w-full" onClick={() => setShowProductDetails(true)}>
          <div className="w-full flex flex-row justify-between">
            <span className="font-bold">
              {pack.product_data[`name_${lang}`]}
            </span>
            <span className="font-bold">{`${pack.product_data?.calorie} ${t(
              "calories"
            )}`}</span>
          </div>
          <div className={classes.packTray}>
            <div className={classes.content}>
              {/* <img
                 src={`${ingredient.image}`}
                alt="tray-pack"
                 className="w-[30%] h-fit "
               /> */}

              {/* {pack.product_data?.food[0]?.products?.map(
                (ingredient, index) => {
                  return (
                    <img
                      src={`${hostAddress}${ingredient.image}`}
                      alt="tray-pack"
                      className={classes.tray}
                    />
                  );
                }
              )} */}
              {pack.product_data?.food?.map((foodType, index) => {
                return foodType?.products?.map((ingredient, index) => {
                  return (
                    <img
                      src={`${hostAddress}${ingredient.image}`}
                      alt="tray-pack"
                      className={classes.tray}
                    />
                  );
                });
              })}
              {/* <img
        src={TrayContentForPack}
        alt="tray-pack"
        className="w-[30%] h-fit "
      />
       */}
            </div>
          </div>
          <div className={`${classes.contentNames}`}>
            {pack.product_data?.food?.map((foodType, foodTypeindex) => {
              return foodType?.products?.map((ingredient, index) => {
                // if is not last product name of category(ingredient)
                return foodType?.products?.length > index + 1 ||
                  // if is not last product category (foodType)
                  pack.product_data?.food.length > foodTypeindex + 1 ? (
                  <span key={ingredient.slug}>{`${
                    ingredient[`name_${lang}`]
                  }, `}</span>
                ) : (
                  <span key={ingredient.slug}>
                    {`${ingredient[`name_${lang}`]}`}
                  </span>
                );
              });
            })}
            {/* {pack.product_data?.food[0]?.products?.map((ingredient, index) => {
              // if item is not last one, we add comma " , " to seprate text items
              return pack.product_data?.food[0]?.products?.length >
                index + 1 ? (
                <span key={ingredient.slug}>{`${
                  ingredient[`name_${lang}`]
                }, `}</span>
              ) : (
                <span key={ingredient.slug}>
                  {`${ingredient[`name_${lang}`]}`}
                </span>
              );
            })} */}
          </div>
        </div>
        <div className="flex flex-row justify-between w-full font-bold">
          <span className={classes.price}>{pack.price} $</span>
          <div className="flex flex-row ">
            <div
              className="p-1 rounded-lg cursor-pointer all-center"
              // onClick={() =>
              //   decreaseCountOfPack(
              //     id,
              //     price,
              //     nutsName,
              //     nutsImage,
              //     orderTypeIsSchool,
              //     temporaryCart,
              //     setTemporaryCart,
              //     count,
              //     cal
              //   )
              // }
            >
              <Minus className="w-6 h-auto" />
            </div>
            <div className="w-7 mx-1 text-center h-full all-center text-[#231F20] font-medium text-base">
              {count}
            </div>
            <div
              className="p-1 rounded-lg cursor-pointer"
              // onClick={() =>
              //   increaseCountOfPack(
              //     id,
              //     price,
              //     count,
              //     nutsName,
              //     nutsImage,
              //     orderTypeIsSchool,
              //     temporaryCart,
              //     setTemporaryCart,
              //     cal
              //   )
              // }
            >
              <Plus className="w-6 h-auto " />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col fr-sm:flex-row fr-sm:items-center items-start  w-full h-full bg-white-text mt-5 rounded-lg "> */}
      {/* <Link
          to="/product-details"
          className="flex flex-row justify-between w-full"
        > */}
      {/* <div
          onClick={() => {
            setShowProductDetails(true);
          }}
          className="flex flex-row justify-between w-full cursor-pointer"
        >
          <div className="w-[80px] flex justify-center items-center">
            <img
              src={nutsImage}
              alt=""
              width={60}
              height={60}
              className="rounded-lg"
            />
          </div>
          <div className="flex flex-col justify-center items-start mx-3 my-2">
            <span className="text-base">{nutsName}</span>
            <span className="text-sm my-1">500 g</span>
            <div className="text-base flex ">
              <span>{`${price} $`}</span>
              <span className="mx-2 px-2 py-1 bg-green-500 rounded-xl text-xs">
                37% DV
              </span>
            </div>
          </div>
          <div className="m-auto mr-[15%] fr-sm:m-auto">
            <span>{`${price * count} $`}</span>
          </div>
        </div>
        <div className="mt-1 mb-3">
          <div className="all-center h-fit fr-sm:mx-3 mx-[80px] bg-white p-[6px]  rounded-md shadow-[#e2e2e2] shadow-sm">
            <div
              className="p-1 rounded-lg cursor-pointer"
              onClick={() =>
                decreaseCountOfPack(
                  id,
                  price,
                  nutsName,
                  nutsImage,
                  orderTypeIsSchool,
                  temporaryCart,
                  setTemporaryCart,
                  count
                )
              }
            >
              <Minus className="w-[16px] h-auto" />
            </div>
            <div className="w-7 mx-1 text-center"> {count} </div>
            <div
              className="p-1 rounded-lg cursor-pointer"
              onClick={() =>
                increaseCountOfPack(
                  id,
                  price,
                  count,
                  nutsName,
                  nutsImage,
                  orderTypeIsSchool,
                  temporaryCart,
                  setTemporaryCart
                )
              }
            >
              <Plus className="w-[16px] h-auto " />
            </div>
          </div>
        </div>
      </div> */}

      <BottomSheetComponent
        showBottomSheet={showProductDetails}
        setShowBottomSheet={setShowProductDetails}
      >
        <ShowproductDetails pack={pack} />
      </BottomSheetComponent>
    </div>
  );
};

export default PackCard;
